/** @format */
import pearsonLogo from './assets/images/logo_footer.png';
import { useEffect, useState } from 'react';
import navigationData from './assets/data/mainnav.json';
import './assets/styles/styles.css';
import Header from './components/Header';
import MainNav from './components/MainNav';

function App(props) {
	var location = window.location;

	//Navigation open
	const [navOpen, setNavOpen] = useState(true);

	//Load this string from localStorage
	const lastActiveNav = localStorage.getItem('lastActiveNav');

	//Current page
	const [currentPage, setCurrentPage] = useState(lastActiveNav || 'dashboard');

	//Navigation
	const [navItems, setNavItems] = useState(
		navigationData.filter((a) => a.type === 'instructor')
	);
	//Page to load in iFrame
	const [pageToLoad, setPageToLoad] = useState();
	const navigatePage = (page) => {
		setCurrentPage(page);
	};

	//This sets the item to localStorage and changes the state index
	function changeCurrentPage(newNav) {
		localStorage.setItem('lastActiveNav', newNav);
		setCurrentPage(newNav);
	}

	const toggleNav = () => {
		setNavOpen(!navOpen);
	};

	//This re-renders when the route changes
	useEffect(() => {
		var activeNavItem = location.pathname;
		if (activeNavItem.indexOf('/') > -1) {
			activeNavItem =
				location.pathname.split('/')[location.pathname.split('/').length - 1];
		}
		if (activeNavItem === 'xl-ov2-modernize') {
			if (studentView) {
				activeNavItem = 'home';
			} else {
				activeNavItem = 'dashboard';
			}
		}
		changeCurrentPage(activeNavItem);
	}, [location]);

	useEffect(() => {
		let navItem = navItems.filter((a) => a.nav === currentPage);
		/* Filter to children if parents don't match*/
		if (navItem.length === 0) {
			let itemsWithChildren = navItems.filter((a) => a.children);
			let childrenNavItem = [];
			itemsWithChildren.forEach((a) => {
				let tempItems = a.children.filter((b) => b.nav === currentPage);
				if (tempItems.length > 0) {
					childrenNavItem.push(tempItems);
				}
			});

			navItem = childrenNavItem[0];
		}

		if (!navItem || !navItem[0]) {
			setPageToLoad(navItems[0].link);
		} else {
			setPageToLoad(navItem[0].link);
		}
	}, [currentPage]);

	return (
		<div className='body-wrap'>
			<Header
				navOpen={navOpen}
				toggleNav={toggleNav}
			/>
			<aside className={`${navOpen ? '' : 'closed'}`}>
				<h2>Mastering A&amp;P</h2>
				<a href='!#'>Back to my courses</a>
				<MainNav
					currentPage={currentPage}
					navItems={navItems}
					navigatePage={navigatePage}
				/>
			</aside>
			<main className={`${navOpen ? '' : 'full-width'}`}>
				<div id='content-player-page-header'>
					<h2>What's new</h2>
				</div>
				<iframe
					src={
						'https://modern-xl-instructor.pearsonct.design/prototypes/modern-xl-instructor/page/assignments/review'
					}
					className='main-iframe'
				/>
			</main>
			<footer
				role='contentinfo'
				className={`${navOpen ? '' : 'full-width'}`}
			>
				<img
					alt='Pearson logo'
					src={pearsonLogo}
				/>
				<div>
					Copyright ©&nbsp;2024&nbsp; Pearson Education Inc. All rights
					reserved.
				</div>
				<div>
					<ul class='ov-footer-items'>
						<li
							class='seperater'
							aria-hidden='true'
						>
							&nbsp;
						</li>
						<li class='seperater'>
							<a
								target='_blank'
								href='https://register.pearsoncmg.com/reg/include/license2.jsp'
								rel='noopener noreferrer'
							>
								Terms of Use
							</a>
						</li>
						<li class='seperater'>
							<a
								target='_blank'
								rel='noopener noreferrer'
								href='https://www.pearson.com/en-us/privacy-center/privacy-notices.html'
							>
								Privacy Policy
							</a>
						</li>
						<li class='seperater'>
							<a
								target='_blank'
								rel='noopener noreferrer'
								href='http://www.pearsoned.com/permissions/'
							>
								Permissions
							</a>
						</li>
						<li class='seperater'>
							<a
								target='_blank'
								rel='noopener noreferrer'
								href='https://support.pearson.com/getsupport'
							>
								Contact Us
							</a>
						</li>
						<li class='seperater'>
							<a
								id='ot-sdk-btn'
								class='ot-sdk-show-settings cookie-button'
							>
								Cookie Settings
							</a>
						</li>
					</ul>
				</div>
			</footer>
		</div>
	);
}

export default App;
