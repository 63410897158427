/** @format */

import { IconButton, Badge } from '@mui/material/';
import UserMenu from './UserMenu';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

const Header = (props) => {
	return (
		<header className='app-header'>
			<div className='left'>
				<button
					class={`hamburger ${props.navOpen ? 'open' : ''}`}
					aria-label='hamburger menu'
					aria-expanded='true'
					onClick={props.toggleNav}
				>
					<div class='bar1'></div>
					<div class='bar2'></div>
					<div class='bar3'></div>
					<div class='bar4'></div>
				</button>
			</div>
			<h1>
				<span>Human Anatomy &amp; Physiology</span>
			</h1>
			<div className='right'>
				<IconButton>
					<HelpOutlineIcon sx={{ width: '24px', height: '24px' }} />
				</IconButton>
				<IconButton sx={{ ml: '-8px', position: 'relative' }}>
					<NotificationsNoneIcon sx={{ width: '24px', height: '24px' }} />
					<span className='badge'>3</span>
				</IconButton>

				<UserMenu />
			</div>
		</header>
	);
};

export default Header;
